import _, { remove } from "lodash";
import { differenceInMinutes } from 'date-fns';
import { inTheFuture, inThePast, fDateTime, isToday } from './formatTime';
// ----------------------------------------------------------------------
import { SETTINGS } from '../config-global';


export function getFormValue(field, data) {
  if(!data[field.id]) {
    return '';
  }
  const values = [];
  switch (field.type) {
    case 'select-field':
      return getWithId(field.options, data[field.id]);

    case 'checkbox-field':        
      Object.keys(data[field.id]).forEach(key => values.push(getWithId(field.options, key)));
      return values.join(", ");

    case 'file-upload':
        return data[field.id];

    default: 
      return data[field.id];
  }

}

export function sortCountries(countries) {
  const list = [];
  countries.forEach((country) => {
    const c = JSON.parse(JSON.stringify(country));
    if(c.countrycode === 'IE') {
      // insert into the list at position 0
      list.unshift(c)
    }
    if(c.countrycode === 'GB') {
      // insert into the list at position 0
      // c.name="";
      list.splice(1, 0, c);
    }
    list.push(c)
  });
  return list;
}

export function fixDetails(ci) {
  if(typeof ci.details === 'string') {
    ci.details = JSON.parse(ci.details);
  }
  return ci;
}

export function getWithId (list, id, property='name') {
  if(!list || !id) {
    return '';
  }
  const obj = _.find(list, {id});
  return obj ? obj[property] : '';
}


export function isString(str) {
  if (str && typeof str.valueOf() === "string") {
    return true;
  }
  return false;
}

export function flattenDeep (arr) {
  return arr.flatMap((subArray) => Array.isArray(subArray) ? flattenDeep(subArray) : subArray
);
}


export function isHorse(type) {
  return type === 1;
}

const statii = [
  { 
    id: 1,
    name: "Current"
  }, 
  { 
    id: 2,
    name: "Duplicate"
  }, 
  { 
    id: 3,
    name: "Dead"
  }, 
  { 
    id: 4,
    name: "Sold Abroad"
  }, 
  { 
    id: 6,            
    name: "Unregistered"
  }, 
  { 
    id: 7,
    name: "Sold"
  }, 
  { 
    id: 8,
    name: "Not Competing"
  }
];

export function getHorseStatii() {  
  return statii;
}

export function horseStatus(statusid) {  
  return statii.find(s => s.id === statusid).name;
}


export function isHorseRegistered (horse) {
  return inTheFuture(horse.regexpirydate);
}

export function isMemberActive (member) {
  return inTheFuture(member.memberexpirydate);
}

export function daysToNow(date) {
  const today = new Date();
  const date_to_reply = new Date(date);
  const timeinmilisec = date_to_reply.getTime() - today.getTime();
  return Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24)) ;
}

export function getHorseAge(datefoaled) {
  // check horse age
  const yearfoaled = new Date(datefoaled).getFullYear();
  const currentYear = new Date().getFullYear();
  return currentYear - yearfoaled;
}

export function isUnderAge(dob) {
  const age = getMemberAge(dob);
  return age <= 18;
}

// get the members age on the 1st of January this year
// 
export function getMemberAge(dob) {
  if(!(dob instanceof Date)) {
    dob = new Date(dob);
  }
  const today = new Date();
  const age = today.getFullYear() - dob.getFullYear();

  console.log(`Returning age:  ${age}`);
  return age;
  // const m = today.getMonth() - dob.getMonth();
  // if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
  //     age -= 1;
  // }
  // return age;
}

export function seasonIsFinished() {
  const now = new Date();
  const seasonEndDate = new Date(`${SETTINGS.SEASON_ENDS}/${now.getFullYear()}`);
  return now > seasonEndDate;
}

export function capitaliseFirstLetter(string) {
  if(!string || string.length === 0) {
      return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export function validateForm (data, groups)  {
  const errorobj = {};
  for(let i = 0; i < groups.length; i += 1) {
    const g = groups[i];
    for(let j = 0; j < g.fields.length; j += 1) {
      const f = g.fields[j];
      const res = validateField(f, data[f.id]);
      if(res) {
        errorobj[f.id] = res;
      }      
    }
  }

  return errorobj;
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function validateField(field, value) {
  let isvalid;
  if(!field.validation) {
    // nothing to validate
    return undefined;
  }
  
  if(field.validation.mandatory) {
    if(typeof value !== 'undefined' && Array.isArray(value) && value.length === 0) {
      return typeof field.validation.mandatory === 'string' ? field.validation.mandatory : "This field is mandatory";
    }
      
    if(typeof value !== 'undefined' && value !== "") {
      return undefined;
    }
    return typeof field.validation.mandatory === 'string' ? field.validation.mandatory : "This field is mandatory";
  }

  // if(field.type === 'drop-file-input') {
    
  // }

  if(field.validation.email) {
    // make sure value entered is a proper email address
    if(!validateEmail(value)) {
      return field.validation.email;
    }
  }

  return isvalid;
}

export function getDateFor(year) {
  return {
    id: year,
    name: `${year}`
  };
}


export function yearsfoaled () {
  const years = [];
  const thisYear = new Date().getFullYear();
  for(let i = SETTINGS.MAX_HORSE_AGE; i >= 4; i -= 1) {
    years.push(getDateFor(thisYear - i));
  }
  return years;
}

// export function inTheFuture(date) {
//   const now = new Date();
//   // console.debug(typeof date);
//   if(typeof date === 'string') {
//     date = new Date(date);
//   }
//   return date > now;
// }

// export function inThePast(date, byDay=false) {
//   const now = new Date();
//   if(typeof date === 'string') {
//     date = new Date(date);
//   }
//   if(byDay) {
//     now.setHours(0,0,0,0);
//   }
//   return date < now;
// }

export function inRange(d1, d2) {
  const start = new Date(d1);
  start.setHours(0,0,0,0);
  const end = new Date(d2);
  end.setHours(24,0,0,0);
  const now = new Date();
  return now.valueOf() >= start.valueOf() && now.valueOf() <= end.valueOf();
}

export function getYearFoaled(horse) {
  if (!horse.datefoaled || horse.datefoaled === "") {
      return "";
  }
  const date = new Date(horse.datefoaled);
  return date.getFullYear();
}


/**
 * Check if entries are still open on an event
 * 
 * @param {*} hasentrylimits set to true if there is a limit on the maximum number of entries allowed
 * @param {*} maxentries the maximum number of entries allowed if a limit is applied
 * @param {*} entriesreceived the numberof entries received so far
 * @param {*} openingdate the opening date for entries
 * @param {*} closingdate the closing date for entries
 * @param {*} islate set to true if late entries are allowed
 * @param {*} lateclosingdate the late entries closing date if late entries are allowed
 */
export function entriesopen(event) {
  const status = eventstatus(event);
  return {
    open: status.open,
    reason: status.description
  }
  // let open = true;
  // let reason = '';
  // // check entry limits
  // if(event.maxentrantsenabled && event.maxentrants >= event.numentries) {
  //   open = false;
  //   reason = 'Maximum number of entries reached';
  // }

  // // check if opening date in future
  // if(inTheFuture(event.openingdate)) {
  //   open = false;
  //   reason = 'Entries not open yet';
  // }

  // // check if late closing date in the past
  // if(event.allowlateentries && inThePast(event.lateclosingdate)) {
  //   open = false;
  //   reason = 'Entries closed';
  // } else if(inThePast(event.closingdate)) {
  //   open = false;
  //   reason = 'Entries closed';
  // }

  // if(!open && event.Lateentries && event.Lateentries.length >0 ) {
  //   const timeLeft = differenceInMinutes(new Date(), new Date(event.Lateentries[0].expirydate));
  //   return {
  //     open: true,
  //     reason: `Time left to make your entry ${timeLeft} (in hours:minutes)`
  //   }
  // }

  // return {
  //   open,
  //   reason
  // };
}

export function convertMinsToHrsMins(minutes) {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  // h = h < 10 ? `0${h}` : h; 
  // m = m < 10 ? `0${m}` : m; 
  if(h === 0) {
    return `${m} minutes`;
  }
  if(m === 0) {
    return `${h} hours`;
  }
  return `${h} hours and ${m} minutes`;
}

export function volunteerstatus(row) {
  // status
  // Upcoming - unconfirmed
  // Upcoming - confirmed
  // Upcoming - can't make it
  // Past - unconfirmed
  const todo = !inThePast(row.eventdate);
  const answered = row.confirmeddate || row.unabledate;

  // const confirmed = row.confirmeddate ? "Confirmed" : 'Unconfirmed';
  // const unable = row.unabledate;

  
  if(todo && !answered) {
    return {
      label: `Unconfirmed`,
      colour: 'error',
      canedit: true,
      candelete: true
    }
  }

  if(todo && answered) {
    return {
      label: row.confirmeddate ? "Confirmed" : 'Unable',
      colour: row.confirmeddate ? "success" : 'warning',
      canedit: false,
      candelete: false
    }
  }

  if(!todo && !answered) {
    return {
      label: "Unconfirmed",
      colour: 'error',
      canedit: false,
      candelete: false
    }
  }

  if(!todo && answered) {
    return {
      label: row.confirmeddate ? "Confirmed" : 'Unable',
      colour: row.confirmeddate ? "success" : 'warning',
      canedit: false,
      candelete: false
    }
  }

  return 'Unknown';
  
}


export function entrystatus(status, isapproved) {
  const statusmap = {
    'APPROVALCOMPLETED': 'Applied',
    'APPROVALPENDING': 'Pending',
    'ENTRYCOMPLETED': 'Complete',
    'ENTRYREFUNDED': 'Refunded',
    'WITHDRAWN': 'Withdrawn',
    'MANUAL': 'Manual',
  };
  if(!statusmap[status]) {
    return 'Unknown';
  }
  
  const statusname = statusmap[status];

  if(statusname === 'Applied' && isapproved) {
    return 'Approved'
  } 
  return statusname;
  
}

export function getTotal(allscores, drpen = 0, othererror = 0) {
  drpen = drpen === "" ? 0 : parseInt(drpen, 10);
  othererror = othererror === "" ? 0 : parseInt(othererror, 10);
  // console.log("All scores", allscores);
  let total = allscores.reduce((partialSum, a) => {
      let amount = parseFloat(a.score);
      if (a.isdouble) {
          amount *= 2;
      }
      return parseFloat(partialSum) + amount
  }, 0);
  // if(test.doublepointsmove !== 0 && allscores[test.doublepointsmove-1]) {
  //     total = total + parseFloat(allscores[test.doublepointsmove-1].score);
  // }
  if (othererror > 0) {
      total -= (othererror * 2)
  }
  if (drpen === 0) {
      return total;
  }
  if (drpen === 1) {
      return total - 2;
  }
  if (drpen === 2) {
      return total - 6;
  }

  return 0;
}

export function getPercentage(allscores, test, drpen = 0, othererror = 0) {
  // console.log("Percentage scores", allscores);
  return (Math.round(100 * parseFloat(getTotal(allscores, drpen === "" ? 0 : drpen, othererror) / test.totalpoints) * 100)) / 100;
}

export function entrystatuscolour(status, isapproved) {
  const statusmap = {
    'APPROVALCOMPLETED': 'warning',
    'APPROVALPENDING': 'error',
    'ENTRYCOMPLETED': 'success',
    'ENTRYREFUNDED': 'info',
    'WITHDRAWN': 'error',
    'MANUAL': 'success',
  };

  if(!statusmap[status]) {
    return 'error';
  }

  if(status === 'APPROVALCOMPLETED' && isapproved) {
    return 'warning'
  } 

  if(status === 'APPROVALCOMPLETED' && !isapproved) {
    return 'error'
  } 

  return (statusmap[status]);
}

export function removeTzInfo(date) {
  if(!date) {
    return date;
  }
  if(date instanceof Date) {
    return date;
  }

  if(date.endsWith('Z')) {
    date = date.substring(0, 19);
  }
  return date;
}
/**
 * Work out the status of the event
 * 
 * @param {*} event 
 */
export function eventstatus(event) {
  const isTraining = event.eventtypeid === SETTINGS.HP_EVENT_TYPE || event.eventtypeid === SETTINGS.TRAINING_EVENT_TYPE;
  if(event.eventtypeid === 7) {
    return {
      open: false,
      label: 'Cancelled',
      colour: 'error',
      tag: 'cancelled',
      description: `Event has been cancelled`,
      limit: ''
    };
  }
  // console.log(event);
  const closingdate = removeTzInfo(event.closingdate);  
  if(event.Lateentries && event.Lateentries.length >0 ) {
    const lateexpirydate = removeTzInfo(event.Lateentries[0].expirydate);
    if(inTheFuture(lateexpirydate)) {
      const minutes = differenceInMinutes(new Date(lateexpirydate), new Date());
      const timeLeft = convertMinsToHrsMins(minutes);
      console.log(`Time now ${new Date()} Expiry ${new Date(lateexpirydate)} - time left ${timeLeft}`);
      return {
        open: true,
        label: 'Special Late Entry',
        colour: 'success',
        tag: 'late',
        description: `You have ${timeLeft} left to make your entry`,
        limit: '',
      }  
    }
  }

  if(event.entriesfull) {
    return {
      open: false,
      label: "Entries Full",
      colour: 'error',
      tag: 'full',
      description: 'Entries are full for this event',
      limit: 'No places left'

    };
  }
  const places =  event.maxentrants - event.numentries;
  const place = places > 1 ? 'places' : 'place';

  // if entries are opening soon
  const openingdate = removeTzInfo(event.openingdate);  
  const daysToOpening = daysToNow(openingdate);
  if(daysToOpening > 0 && daysToOpening <= 2) {
    return {
      open: false,
      label: 'Opening Soon',
      colour: 'warning',
      tag: 'soonopen',
      description: `Entries are opening soon (${fDateTime(openingdate)})`,
      limit: event.maxentrantsenabled ? `Max. of ${event.maxentrants} entries` : ''
    };
  }

  // if entries are not open yet
  if(daysToOpening >= 0 && inTheFuture(openingdate)) {
    return {
      open: false,
      label: 'Not Open Yet',
      colour: 'default',
      tag: 'notopenyet',
      description: `Entries are not open yet (opening: ${fDateTime(openingdate)})`,
      limit: event.maxentrantsenabled ? `Max. of ${event.maxentrants} entries` : ''
    };
  }


  // if event end date is in the past then it has already happened
  if(!isToday(event.eventenddate) && inThePast(event.eventenddate, true)) {
    return {
      open: false,
      label: 'Finished',
      colour: 'default',
      tag: 'past',
      description: 'Entries for this event are closed',
      limit: ''
    };
  }

  // if the current date is in range between startdate and eventenddate then it is live
  if(!isTraining && (isToday(event.eventenddate) || inRange(event.startdate, event.eventenddate))) {
    return {
      open: false,
      label: 'Live',
      colour: 'info',
      tag: 'live',
      description: 'The event is currently taking place',
      limit: `${event.numentries} entered`
    };
  }

  // if entries are open but closing soon
  const daysToClosing = daysToNow(closingdate);
  if(daysToClosing >= 0 && daysToClosing <= 2 && inTheFuture(closingdate)) {
    // check entry limits first
    if(limitsExceeded(event)) {
      return {
        open: false,
        label: "Entries Full",
        colour: 'error',
        tag: 'full',
        description: 'Entries are full for this event',
        limit: 'No places left'

      };
    }
    // const place = places > 1 ? 'places' : 'place';

    let description = `Entries are closing soon (${fDateTime(closingdate)})`
    if(event.maxentrantsenabled) {      
      // const place = places > 1 ? 'places' : 'place';

      if(places < 10) {
        description += ` with only ${places} ${place} left`;
      } else {
        description += ` with ${places} ${place} left`;
      }
  
    } 
    return {
      open: true,
      label: 'Closing Soon',
      colour: 'success',
      tag: 'soonclose',
      description,
      limit: event.maxentrantsenabled ? `${places} ${place} left` : `${event.numentries} entries`
    };
  }

  // closing in the future
  if(inTheFuture(closingdate)) {
    if(limitsExceeded(event)) {
      return {
        open: false,
        label: "Entries Full",
        colour: 'error',
        tag: 'full',
        description: 'Entries are full for this event',
        limit: 'No places left'
      };
    }

    return {
      open: true,
      label: 'Open',
      colour: 'success',
      tag: 'open',
      description: `Entries are open for this event (closing ${fDateTime(closingdate)})`,
      limit: event.maxentrantsenabled ? `${places} ${place} left` : `${event.numentries} entered`
    };
  }

  // open with late entries
  if(event.allowlateentries) {
    const closingdatelateentries = removeTzInfo(event.closingdatelateentries);
    if(inTheFuture(closingdatelateentries)) {
      if(limitsExceeded(event)) {
        return {
          open: false,
          label: "Entries Full",
          colour: 'error',
          tag: 'full',
          description: 'Entries are full for this event',
          limit: 'No places left'
        };
      }
  
      let description = 'Late entries are accepted for this event'
      if(event.maxentrantsenabled) {
        // const place = places > 1 ? 'places' : 'place';

        if(places < 10) {
          description += ` with only ${places} ${place} left`;
        } else {
          description += ` with ${places} ${place} left`;
        }
    
      } 
      // const place = places > 1 ? 'places' : 'place';
      return {
        open: true,
        label: 'Late Entries Accepted',
        colour: 'success',
        tag: 'late',
        description: `${description} (closing ${fDateTime(closingdatelateentries)})`,
        limit: event.maxentrantsenabled ? `${places} ${place} left` : ''
      };
    }
  }

  // if the current date is in range between closing date and eventenddate then it is live
  if(inRange(event.closingdate, event.startdate)) {
    return {
      open: false,
      label: 'Closed',
      colour: 'error',
      tag: 'closed',
      description: 'Entries closed',
      limit: `${event.numentries} entered`
    };
  }
  
  return {
    open: true,
    label: '??',
    colour: 'error',
    description: 'Problem getting status of event, contact support'
  };


}

export function isEuro(currency) {
  if(currency === SETTINGS.POUND || currency === 2 || currency === 'GBP' ) {
    return false;
  }
  return true;
}


export function venueCurrency(venue) {
  return venue.countryid === 248 ? SETTINGS.POUND : SETTINGS.EURO;  
}

export function isEuroVenue(venue) {
  return venueCurrency(venue) === SETTINGS.EURO;  
}

export function isEuroCurrencyCode(code) {
  if(typeof code === 'string') {
    return code === 'EUR';
  }
  return code === 1;  
}


export function limitsExceeded(event) {
  return (event.maxentrantsenabled && event.maxentries >= event.numentries) 
}


export function classname(eventclass) {
  return eventclass.sponsortext ? eventclass.sponsortext : eventclass.Classtype.name;
}

export const orlist = (list) => {
  if(!list || list.length === 0) {
    return '';
  }

  if(list.length === 1) {
    return pluralise(list[0]);
  }

  // list = list.map(r => pluralise(r));

  return `${list.slice(0, list.length-1).join(", ")} or ${list[list.length-1]}`;
}

export const pluralise = (name) => {
  if(name.toLowerCase() === 'pony') {
    return 'ponies';
  }
  return `${name}s`;
}

const ERQI_LEVEL_PENDING = {
  title: 'E',
  lower: -5,
  upper: 0,
  restricted: true,
  bgcolour: 'rgba(239,124,124,1)'
};

const ERQI_LEVELS = {
  UNKNOWN_TITLE: 'Unknown',
  PENDING_VALUE: 8001,
  NONE: {
      title: 'No Restrictions',
      restricted: false,
      bgcolour: 'rgba(173, 216, 230, 1)',
      description: "No restrictions in place"
  },
  PENDING: {
      title: 'Pending',
      restricted: false,
      bgcolour: 'rgba(173, 216, 230, 1)',
      description: 'A minimum of three runs XC are required to assess this horses form.'
  },
  UNKNOWN: {
      title: 'Unknown',
      restricted: false,
      bgcolour: 'rgba(173, 216, 230, 1)',
      description: "Horse Safety Rating Unknown"
  },
  TABLE: [
      {
          title: 'E',
          lower: -5,
          upper: 0,
          restricted: true,
          bgcolour: 'rgba(239,124,124,1)',
          description: 'Sorry the ERQI Rating for this horse is not sufficient for this class'
      },
      {
          title: 'D',
          lower: 0,
          upper: 1,
          restricted: false,
          bgcolour: 'rgba(255,188,71,0.8)',
          description: 'The ERQI Rating for this level indicates low form for this level'
      },
      {
          title: 'C',
          lower: 1,
          upper: 2,
          restricted: false,
          bgcolour: 'rgba(97,202,49,0.15)',
          description: 'The ERQI Rating for this level indicates below average form'
      },
      {
          title: 'B',
          lower: 2,
          upper: 3,
          restricted: false,
          bgcolour: 'rgba(97,202,49,0.3)',
          description: 'The ERQI Rating for this level indicates above average form'
      },
      {
          title: 'A',
          lower: 3,
          upper: 4,
          restricted: false,
          bgcolour: 'rgba(97,202,49,0.5)',
          description: 'The ERQI Rating for this level indicates good form'
      },
      {
          title: 'A+',
          lower: 4,
          upper: 5,
          restricted: false,
          bgcolour: 'rgba(97,202,49,0.65)',
          description: 'The ERQI Rating for this level indicates very good form'
      },
      {
          title: 'A++',
          lower: 5,
          upper: 8,
          restricted: false,
          bgcolour: 'rgba(97,202,49,0.8)',
          description: 'The ERQI Rating for this level indicates excellent form'
      }
  ]
};

export const erqiresult = (erqilevel, score, valid = true) => ({
      ...erqilevel,
      rating: score,
      apply: valid && erqilevel.title !== ERQI_LEVELS.UNKNOWN_TITLE
  });
  


export const getErqiLevel = (horserating) => {
  for (let i = 0; i < ERQI_LEVELS.TABLE.length; i+=1) {
      if (horserating >= ERQI_LEVELS.TABLE[i].lower && horserating < ERQI_LEVELS.TABLE[i].upper) {
          return ERQI_LEVELS.TABLE[i];
      }
  }
  return ERQI_LEVELS.UNKNOWN;
}

export const checkEquiRating = (equiclass, horse) => {

  if (equiclass.id === 0) {
      return erqiresult(ERQI_LEVELS.NONE, 0, false);
  }

  if (equiclass.id === ERQI_LEVELS.PENDING_VALUE) {
      return erqiresult(ERQI_LEVELS.PENDING, 0, false);
  }

  const horserating = horse[equiclass.horse_column];
  return erqiresult(getErqiLevel(horserating), horserating);

}