import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
// utils
import { formatCurrency } from '../../../utils/formatNumber';

// _mock_
import { _notifications } from '../../../_mock/arrays';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { SETTINGS } from '../../../config-global';

// ----------------------------------------------------------------------

export default function PoundCartPopover() {
  const [openPopover, setOpenPopover] = useState(null);
  const { poundcart, isLoading } = useSelector((state) => state.poundcart);
  const navigate = useNavigate();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleGotoCart = () =>  {
    setOpenPopover(null);
    navigate(PATH_DASHBOARD.general.cart(SETTINGS.POUND));    
  }

  return (
    <>
    {!isLoading && 
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={poundcart.items.length} color="error">
          £ <Iconify icon="eva:shopping-cart-fill" />
        </Badge>
      </IconButtonAnimate>}

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Pound Sterling Cart</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {poundcart.items.length} item in your £ cart
            </Typography>
          </Box>

        </Box>

        {poundcart.items.length > 0 && <Divider sx={{ borderStyle: 'dashed' }} />}

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
          >
            {poundcart.items.slice(0, SETTINGS.CART_POPOVER_NUM_ITEMS).map((item) => (
              <PoundCartItem key={item.id} item={item} />
            ))}
            {poundcart.items.length > SETTINGS.CART_POPOVER_NUM_ITEMS && <ListItem><Divider sx={{ borderStyle: 'dashed' }} /><ListItemText>With {poundcart.items.length-SETTINGS.CART_POPOVER_NUM_ITEMS} more in the cart</ListItemText></ListItem>}
          </List>
        </Scrollbar>


        {poundcart.items.length > 0 && <Box sx={{ p: 1 }}>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Button fullWidth disableRipple onClick={() => handleGotoCart()}>
            Go To Cart
          </Button>
        </Box>}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

PoundCartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.node,
    amount: PropTypes.string,
  }),
};

function PoundCartItem({ item }) {
  const { description, amount } = item;

  return (
    <ListItem>
      <ListItemText
      sx={{typography: 'caption' }}
      disableTypography
        primary={description}
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Typography variant="caption">{formatCurrency(amount, 'pound')}</Typography>
            </Stack>
        }
      />
    </ListItem>
  );
}


