import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: []
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getEventsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function loadEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/account/my/events');
      // console.debug(response.data);
      dispatch(slice.actions.getEventsSuccess(response.data.records ? response.data.records : []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export const getEvents = state => state.events;
