
// ----------------------------------------------------------------------

export const _accountitems = [
  {
    label: 'Member Type',
    value: 'Life'
  },
  {
    label: 'Riding Option',
    value: 'Non Riding'
  },
  {
    label: 'Membership Expiry',
    value: '22 Jan 2023'
  },
  {
    label: 'Registered Horses',
    value: '4'
  },
  {
    label: 'Elapsed Horse Registrations',
    value: '2'
  },
  {
    label: 'Next Registration Due',
    value: '21 Mar 2023'
  }
];

export const _myhorses = [
  {
    id: 213049,
    registrationnumber: "19092",
    regexpirydate: "2023-03-02",
    name: "Deniro de Semilly (ISH)",
    colour: "Chestnut",
    height: 172,
    gender: 1,
    datefoaled: "2013-01-01",
    type: 1,
    grade: 4,
    status: 1,
    pointstotal: 0,
    pointsatregistration: 0,
    pointsthisyear: 0,
    ownernametext: "Fiona  Cosgrave ",
    passportissuer: "Irish Horse Register",
    sirename: "Pacino (BEWP)",
    damname: "Flagmount Flame (RID)",    
  },
  {
    id: 211359,
    registrationnumber: "17999",
    regexpirydate: "2023-03-03",
    name: "Milchem Ardeo (ISH)",
    colour: "Bay",
    height: 163,
    gender: 1,
    datefoaled: "2011-01-01",
    type: 1,
    grade: 2,
    status: 1,
    regtypeid: 6,
    pointstotal: 56,
    pointsatregistration: 0,
    pointsthisyear: 0,
    ownernametext: "Michael Adams",
    passportissuer: "Irish Horse Register",
    sirename: "Pacino (BEWP)",
    damname: "Flagmount Flame (RID)",    
  },
  {
    id: 216011,
    registrationnumber: "20878",
    regexpirydate: "2020-01-21",
    name: "Blizzard",
    colour: "Grey",
    height: 146,
    gender: 1,
    datefoaled: "2009-01-01",
    type: 2,
    grade: 2,
    status: 1,
    regtypeid: 5,
    pointstotal: 0,
    pointsatregistration: 0,
    pointsthisyear: 0,
    ownernametext: "Michael Adams ",
    passportissuer: "Irish Horse Register",
    sirename: "Pacino (BEWP)",
    damname: "Flagmount Flame (RID)",    
  },
  {
    id: 12211,
    registrationnumber: "13448S",
    regexpirydate: "2020-01-21",
    name: "Toberpatrick Flash",
    colour: "Chestnut",
    height: 147,
    gender: 1,
    datefoaled: "2004-01-01",
    type: 2,
    grade: 2,
    regtypeid: 5,
    status: 1,
    pointstotal: 0,
    pointsatregistration: 0,
    pointsthisyear: 0,
    ownernametext: "Michael Adams ",
    passportissuer: "Irish Horse Register",
    sirename: "Pacino (BEWP)",
    damname: "Flagmount Flame (RID)",    
  }
];

export const _approvals = [
  {
    id: 1,
    horsename: 'Lugano Lad',
    ridername: "Julia Adams",
    eventname: 'Millstreet International',
    eventdate: new Date('2023-06-02'),
    classname: 'CCI2*-L',
    status: 'awaiting'
  },
  {
    id: 2,
    horsename: 'Bubbles',
    ridername: "Grace Adams",
    eventname: 'Millstreet International',
    eventdate: new Date('2023-06-02'),
    classname: 'CCI3*-L',
    status: 'pending'
  },
  {
    id: 3,
    horsename: 'Patience Sweeney',
    ridername: "Hannah Adams",
    eventname: 'Millstreet International',
    eventdate: new Date('2023-06-02'),
    classname: 'CCI1*-Intro',
    status: 'approved'
  }
];

export const _entries = [
  {
    id: 1,
    eventid: 8887,
    horseid: 10,
    horsename: 'Milchem Ardeo',
    ridername: "Julia Adams",
    eventname: 'Millstreet International',
    eventdate: nowPlus(23),
    entrydate: nowMinus(3),
    classname: 'CCI2*-L',
    type: 'entry',
    status: 'upcoming'
  },
  {
    id: 2,
    eventid: 8887,
    horseid: 11,
    horsename: 'Toberpatrick Flash',
    ridername: "Grace Adams",
    eventname: 'Millstreet International',
    classname: 'CCI1*-Intro',
    type: 'entry',
    status: 'upcoming',
    eventdate: nowPlus(23),
    entrydate: nowMinus(3),
  },
  {
    id: 10,
    eventid: 8887,
    horseid: 12,
    horsename: 'Toberpatrick Flash',
    ridername: "Hannah Adams",
    eventname: 'Millstreet International',
    classname: 'CCI1*-Intro',
    type: 'application',
    status: 'approved',
    eventdate: nowPlus(23),
    entrydate: nowMinus(3),
  },
  {
    id: 11,
    eventid: 8887,
    horseid: 13,
    horsename: 'Blizzard',
    ridername: "Michael Adams",
    eventname: 'Millstreet International',
    classname: 'CCI1*-Intro',
    type: 'application',
    status: 'pending',
    eventdate: nowPlus(23),
    entrydate: nowMinus(3),
  }
];

export const _allentries = [
  ..._entries,
  {
    id: 3,
    eventid: 8887,
    horseid: 1,
    horsename: 'Toberpatrick Flash',
    ridername: "Grace Adams",
    eventname: 'Punchestown 2',
    eventdate: nowMinus(207),
    entrydate: nowMinus(217),
    type: 'entry',
    classname: 'EI100P',
    status: 'past'
  },
  {
    id: 4,
    eventid: 8887,
    horseid: 2,
    horsename: 'Toberpatrick Flash',
    ridername: "Grace Adams",
    eventname: 'Tyrella 2',
    eventdate: nowMinus(200),
    entrydate: nowMinus(213),
    classname: 'EI100P',
    type: 'entry',
    status: 'past',
  },
  {
    id: 5,
    eventid: 8887,
    horseid: 3,
    horsename: 'Toberpatrick Flash',
    ridername: "Grace Adams",
    eventname: 'Blackstairs 1',
    eventdate: nowMinus(217),
    entrydate: nowMinus(227),
    classname: 'EI100P',
    type: 'entry',
    status: 'past',
  },
  {
    id:6,
    eventid: 8887,
    horseid: 4,
    horsename: 'Toberpatrick Flash',
    ridername: "Grace Adams",
    eventname: 'Tyrella 1',
    eventdate: nowMinus(224),
    entrydate: nowMinus(238),
    classname: 'EI100P',
    type: 'entry',
    status: 'past',
  }

];

export const _classtypes = [
  {
    "id": 20,
    "name": "EI110 (J)",
    "description": "",
    "eventtypeid": 5,
    "maxpoints": 999,
    "minpoints": 0,
    "minhorseage": 5,
    "maxhorseage": 30,
    "archived": 0,
    "internationalclasstype": 0,
    "ordernum": 18,
    "issportsman": 0,
    "classtypelevelid": 1,
    "minriderage": 14,
    "maxriderage": 18,
    "entrancefeeeuro": "105",
    "entrancefeegbp": "83",
    "applicationentryeuro": null,
    "ponyplus": 0,
    "equiclassesid": 4,
    "minponyage": 0,
    "RefRidingoptions": [
        {
            "id": 7,
            "name": "Junior",
            "archived": 0,
            "price": "30.00",
            "minage": 14,
            "maxage": 18,
            "parentalconsent": 1,
            "birthcertcheck": 1,
            "ClasstypeRidingoption": {
                "classtypeid": 20,
                "ridingoptionid": 7,
                "ClasstypeId": 20
            }
        },
        {
            "id": 8,
            "name": "Pony",
            "archived": 0,
            "price": "30.00",
            "minage": 12,
            "maxage": 16,
            "parentalconsent": 1,
            "birthcertcheck": 1,
            "ClasstypeRidingoption": {
                "classtypeid": 20,
                "ridingoptionid": 8,
                "ClasstypeId": 20
            }
        },
        {
            "id": 13,
            "name": "Overseas",
            "archived": 0,
            "price": "305.00",
            "minage": 18,
            "maxage": 3000,
            "parentalconsent": 0,
            "birthcertcheck": 0,
            "ClasstypeRidingoption": {
                "classtypeid": 20,
                "ridingoptionid": 13,
                "ClasstypeId": 20
            }
        }
    ],
    "Membertypes": [
        {
            "id": 2,
            "name": "Full Member",
            "archived": 0,
            "price": "65.00",
            "ClasstypeMembertype": {
                "classtypeid": 20,
                "membertypeid": 2,
                "ClasstypeId": 20
            }
        },
        {
            "id": 4,
            "name": "Life",
            "archived": 0,
            "price": "2500.00",
            "ClasstypeMembertype": {
                "classtypeid": 20,
                "membertypeid": 4,
                "ClasstypeId": 20
            }
        }
    ],
    "RefHorsetypes": [
        {
            "id": 1,
            "name": "Horse",
            "archived": 0,
            "price": "0.00",
            "ClasstypeHorsetype": {
                "classtypeid": 20,
                "horsetypeid": 1,
                "ClasstypeId": 20
            }
        }
    ],
    "RefHorseregtypes": [
        {
            "id": 1,
            "name": "Horse 0+ points",
            "minage": 5,
            "maxage": 3000,
            "minheight": 148,
            "maxheight": 182,
            "archived": 0,
            "price": "70.00",
            "minpoints": 0,
            "maxpoints": 10,
            "ClasstypeHorseregtype": {
                "classtypeid": 20,
                "horseregtypeid": 1,
                "ClasstypeId": 20
            }
        },
        {
            "id": 6,
            "name": "Horse 11-20 points",
            "minage": 5,
            "maxage": 3000,
            "minheight": 148,
            "maxheight": 182,
            "archived": 0,
            "price": "75.00",
            "minpoints": 11,
            "maxpoints": 20,
            "ClasstypeHorseregtype": {
                "classtypeid": 20,
                "horseregtypeid": 6,
                "ClasstypeId": 20
            }
        },
        {
            "id": 7,
            "name": "Horse 20+ points",
            "minage": 5,
            "maxage": 3000,
            "minheight": 148,
            "maxheight": 182,
            "archived": 0,
            "price": "80.00",
            "minpoints": 21,
            "maxpoints": 9999,
            "ClasstypeHorseregtype": {
                "classtypeid": 20,
                "horseregtypeid": 7,
                "ClasstypeId": 20
            }
        },
        {
            "id": 4,
            "name": "Pony",
            "minage": 5,
            "maxage": 3000,
            "minheight": 138,
            "maxheight": 148,
            "archived": 0,
            "price": "55.00",
            "minpoints": 0,
            "maxpoints": 9999,
            "ClasstypeHorseregtype": {
                "classtypeid": 20,
                "horseregtypeid": 4,
                "ClasstypeId": 20
            }
        }
    ],
    "RefHorsegrades": [
        {
            "id": 1,
            "name": "A",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 20,
                "horsegradeid": 1,
                "ClasstypeId": 20
            }
        },
        {
            "id": 2,
            "name": "B",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 20,
                "horsegradeid": 2,
                "ClasstypeId": 20
            }
        }
    ],
    "RefMembergrades": [
        {
            "id": 1,
            "name": "A",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 20,
                "membergradeid": 1,
                "ClasstypeId": 20
            }
        },
        {
            "id": 2,
            "name": "B",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 20,
                "membergradeid": 2,
                "ClasstypeId": 20
            }
        }
    ],
  },
  {
    "id": 216,
    "name": "HP Pony Training (All Phases)",
    "description": "",
    "eventtypeid": 6,
    "maxpoints": 9999,
    "minpoints": 0,
    "minhorseage": 6,
    "maxhorseage": 30,
    "archived": 0,
    "internationalclasstype": 0,
    "ordernum": 60,
    "issportsman": 0,
    "classtypelevelid": 4,
    "minriderage": 12,
    "maxriderage": 16,
    "entrancefeeeuro": "50",
    "entrancefeegbp": null,
    "applicationentryeuro": null,
    "ponyplus": 0,
    "equiclassesid": 1,
    "minponyage": 0,
    "RefRidingoptions": [
        {
            "id": 7,
            "name": "Junior",
            "archived": 0,
            "price": "30.00",
            "minage": 14,
            "maxage": 18,
            "parentalconsent": 1,
            "birthcertcheck": 1,
            "ClasstypeRidingoption": {
                "classtypeid": 216,
                "ridingoptionid": 7,
                "ClasstypeId": 216
            }
        },
        {
            "id": 8,
            "name": "Pony",
            "archived": 0,
            "price": "30.00",
            "minage": 12,
            "maxage": 16,
            "parentalconsent": 1,
            "birthcertcheck": 1,
            "ClasstypeRidingoption": {
                "classtypeid": 216,
                "ridingoptionid": 8,
                "ClasstypeId": 216
            }
        }
    ],
    "Membertypes": [
        {
            "id": 2,
            "name": "Full Member",
            "archived": 0,
            "price": "65.00",
            "ClasstypeMembertype": {
                "classtypeid": 216,
                "membertypeid": 2,
                "ClasstypeId": 216
            }
        },
        {
            "id": 4,
            "name": "Life",
            "archived": 0,
            "price": "2500.00",
            "ClasstypeMembertype": {
                "classtypeid": 216,
                "membertypeid": 4,
                "ClasstypeId": 216
            }
        }
    ],
    "RefHorsetypes": [
        {
            "id": 2,
            "name": "Pony",
            "archived": 0,
            "price": "0.00",
            "ClasstypeHorsetype": {
                "classtypeid": 216,
                "horsetypeid": 2,
                "ClasstypeId": 216
            }
        }
    ],
    "RefHorseregtypes": [
        {
            "id": 4,
            "name": "Pony",
            "minage": 5,
            "maxage": 3000,
            "minheight": 138,
            "maxheight": 148,
            "archived": 0,
            "price": "55.00",
            "minpoints": 0,
            "maxpoints": 9999,
            "ClasstypeHorseregtype": {
                "classtypeid": 216,
                "horseregtypeid": 4,
                "ClasstypeId": 216
            }
        }
    ],
    "RefHorsegrades": [
        {
            "id": 1,
            "name": "A",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 216,
                "horsegradeid": 1,
                "ClasstypeId": 216
            }
        },
        {
            "id": 2,
            "name": "B",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 216,
                "horsegradeid": 2,
                "ClasstypeId": 216
            }
        },
        {
            "id": 3,
            "name": "C",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 216,
                "horsegradeid": 3,
                "ClasstypeId": 216
            }
        },
        {
            "id": 4,
            "name": "U",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 216,
                "horsegradeid": 4,
                "ClasstypeId": 216
            }
        }
    ],
    "RefMembergrades": [
        {
            "id": 1,
            "name": "A",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 216,
                "membergradeid": 1,
                "ClasstypeId": 216
            }
        },
        {
            "id": 2,
            "name": "B",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 216,
                "membergradeid": 2,
                "ClasstypeId": 216
            }
        },
        {
            "id": 3,
            "name": "C",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 216,
                "membergradeid": 3,
                "ClasstypeId": 216
            }
        },
        {
            "id": 4,
            "name": "U",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 216,
                "membergradeid": 4,
                "ClasstypeId": 216
            }
        }
    ],
  },
  {
    "id": 216,
    "name": "HP Pony Training (All Phases)",
    "description": "",
    "eventtypeid": 6,
    "maxpoints": 9999,
    "minpoints": 0,
    "minhorseage": 6,
    "maxhorseage": 30,
    "archived": 0,
    "internationalclasstype": 0,
    "ordernum": 60,
    "issportsman": 0,
    "classtypelevelid": 4,
    "minriderage": 12,
    "maxriderage": 16,
    "entrancefeeeuro": "50",
    "entrancefeegbp": null,
    "applicationentryeuro": null,
    "ponyplus": 0,
    "equiclassesid": 1,
    "minponyage": 0,
    "RefRidingoptions": [
        {
            "id": 7,
            "name": "Junior",
            "archived": 0,
            "price": "30.00",
            "minage": 14,
            "maxage": 18,
            "parentalconsent": 1,
            "birthcertcheck": 1,
            "ClasstypeRidingoption": {
                "classtypeid": 216,
                "ridingoptionid": 7,
                "ClasstypeId": 216
            }
        },
        {
            "id": 8,
            "name": "Pony",
            "archived": 0,
            "price": "30.00",
            "minage": 12,
            "maxage": 16,
            "parentalconsent": 1,
            "birthcertcheck": 1,
            "ClasstypeRidingoption": {
                "classtypeid": 216,
                "ridingoptionid": 8,
                "ClasstypeId": 216
            }
        }
    ],
    "Membertypes": [
        {
            "id": 2,
            "name": "Full Member",
            "archived": 0,
            "price": "65.00",
            "ClasstypeMembertype": {
                "classtypeid": 216,
                "membertypeid": 2,
                "ClasstypeId": 216
            }
        },
        {
            "id": 4,
            "name": "Life",
            "archived": 0,
            "price": "2500.00",
            "ClasstypeMembertype": {
                "classtypeid": 216,
                "membertypeid": 4,
                "ClasstypeId": 216
            }
        }
    ],
    "RefHorsetypes": [
        {
            "id": 2,
            "name": "Pony",
            "archived": 0,
            "price": "0.00",
            "ClasstypeHorsetype": {
                "classtypeid": 216,
                "horsetypeid": 2,
                "ClasstypeId": 216
            }
        }
    ],
    "RefHorseregtypes": [
        {
            "id": 4,
            "name": "Pony",
            "minage": 5,
            "maxage": 3000,
            "minheight": 138,
            "maxheight": 148,
            "archived": 0,
            "price": "55.00",
            "minpoints": 0,
            "maxpoints": 9999,
            "ClasstypeHorseregtype": {
                "classtypeid": 216,
                "horseregtypeid": 4,
                "ClasstypeId": 216
            }
        }
    ],
    "RefHorsegrades": [
        {
            "id": 1,
            "name": "A",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 216,
                "horsegradeid": 1,
                "ClasstypeId": 216
            }
        },
        {
            "id": 2,
            "name": "B",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 216,
                "horsegradeid": 2,
                "ClasstypeId": 216
            }
        },
        {
            "id": 3,
            "name": "C",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 216,
                "horsegradeid": 3,
                "ClasstypeId": 216
            }
        },
        {
            "id": 4,
            "name": "U",
            "archived": 0,
            "ClasstypeHorsegrade": {
                "classtypeid": 216,
                "horsegradeid": 4,
                "ClasstypeId": 216
            }
        }
    ],
    "RefMembergrades": [
        {
            "id": 1,
            "name": "A",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 216,
                "membergradeid": 1,
                "ClasstypeId": 216
            }
        },
        {
            "id": 2,
            "name": "B",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 216,
                "membergradeid": 2,
                "ClasstypeId": 216
            }
        },
        {
            "id": 3,
            "name": "C",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 216,
                "membergradeid": 3,
                "ClasstypeId": 216
            }
        },
        {
            "id": 4,
            "name": "U",
            "archived": 0,
            "ClasstypeMembergrade": {
                "classtypeid": 216,
                "membergradeid": 4,
                "ClasstypeId": 216
            }
        }
    ]
  }
];


export const _classes = {
  hp: [
    {
      id: 1,
      name: 'Dressage With Gerard Bloomer',
      hasentrylimits: true,
      maxentries: 8,
      numentries: 5,
      currency: 'pound',
      entryfee: 55.00,
      isinternationalclass: false,
      classtype: _classtypes[1],
      eventname: 'High Performance Training (Junior)',
      eventid: 7,
      eventdate: nowPlus(15),
    },
    {
      id: 2,
      name: 'Show Jumping With Dag Albert',
      hasentrylimits: true,
      maxentries: 12,
      currency: 'pound',
      numentries: 8,
      entryfee: 35.00,
      isinternationalclass: false,
      classtype: _classtypes[1],
      eventname: 'High Performance Training (Junior)',
      eventid: 7,
      eventdate: nowPlus(15),
    }
  ],
  national: [
    {
      id: 3,
      name: 'EI115',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 105.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 4,
      name: 'EI115 (Open)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 105.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 5,
      name: 'EI110 (Open)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 6,
      name: 'EI110 (J)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 7,
      name: 'EI110 (P)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 8,
      name: 'EI100 (Open)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 9,
      name: 'EI100 (J)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 10,
      name: 'EI100 (P)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 11,
      name: 'EI90',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 12,
      name: 'EI90 (J)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 13,
      name: 'EI90 (P)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 14,
      name: 'EI90 (T)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    },
    {
      id: 15,
      name: 'EI80 (T)',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 95.00,
      isinternationalclass: false,
      dressagetest: 'FEI CCI1* (2022)',
      classtype: _classtypes[0],
      eventname: 'Punchestown 3',
      eventdate: nowPlus(10),
      eventid: 2,
    }
  ],
  international: [
    {
      id: 16,
      name: 'CCI4*-L',
      sponsorname: 'NutriScience CCI4*-L',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 305.00,
      isinternationalclass: true,
      dressagetest: 'FEI CCI4*A (2022)',
      classtype: _classtypes[2],
      eventname: 'Ballindenisk International',
      eventdate: nowPlus(25),
      eventid: 8,
    },
    {
      id: 17,
      name: 'CCI3*-L',
      sponsorname: 'ARK Equine CCI3*-L',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 305.00,
      isinternationalclass: true,
      dressagetest: 'FEI CCI3*A (2022)',
      classtype: _classtypes[2],
      eventname: 'Ballindenisk International',
      eventdate: nowPlus(25),
      eventid: 8,
    },
    {
      id: 18,
      name: 'CCI2*-L',
      sponsorname: 'Horse Sport Ireland CCI2*-L',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 305.00,
      isinternationalclass: true,
      dressagetest: 'FEI CCI2*A (2022)',
      classtype: _classtypes[2],
      eventname: 'Ballindenisk International',
      eventdate: nowPlus(25),
      eventid: 8,
    },
    {
      id: 19,
      name: 'CCI3*-S',
      sponsorname: 'NutriScience CCI3*-S',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 305.00,
      isinternationalclass: true,
      dressagetest: 'FEI CCI3*A (2022)',
      classtype: _classtypes[2],
      eventname: 'Ballindenisk International',
      eventdate: nowPlus(25),
      eventid: 8,
    },
    {
      id: 20,
      name: 'CCI2*-S',
      sponsorname: 'ARK Equine CCI2*-S',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 305.00,
      isinternationalclass: true,
      dressagetest: 'FEI CCI2*A (2022)',
      classtype: _classtypes[2],
      eventname: 'Ballindenisk International',
      eventdate: nowPlus(25),
      eventid: 8,
    },
    {
      id: 21,
      name: 'CCI1*-Intro',
      sponsorname: 'NutriScience CCI1*-Intro',
      hasentrylimits: false,
      maxentries: 8,
      numentries: 5,
      currency: 'euro',
      entryfee: 305.00,
      isinternationalclass: true,
      dressagetest: 'FEI CCI1*A (2022)',
      classtype: _classtypes[2],
      eventname: 'Ballindenisk International',
      eventdate: nowPlus(25),
      eventid: 8,
    },

  ]
};
export const _events = [
  {
    id: 1,
    name: "Millstreet (Live Now)",
    eventdate: new Date(),
    openingdate: nowMinus(25),
    closingdate: nowMinus(10),
    isopen: false,
    islate: false,
    islive: true,
    hasentrylimits: true,
    entriesreceived: 122,
    maxentries: 250,
    classes: _classes.international,
    entrieslink: "",
    resultslink: "https://eventingireland.com/",
    status: 'live',
  },
  {
    id: 2,
    name: "Punchestown (Late Entries)",
    eventdate: nowPlus(5),
    classes: _classes.national,
    openingdate: nowMinus(7),
    closingdate: nowPlus(3),
    isopen: true,
    islate: true,
    islive: false,
    hasentrylimits: true,
    entriesreceived: 205,
    maxentries: 250,
    entrieslink: "https://eventingireland.com/",
    resultslink: "",
    status: 'late',
  },
  {
    id: 8,
    name: "Ballindenisk International",
    eventdate: nowPlus(10),
    openingdate: nowMinus(10),
    closingdate: nowMinus(2),
    isopen: false,
    islate: false,
    islive: false,
    hasentrylimits: true,
    entriesreceived: 122,
    maxentries: 250,
    classes: _classes.international,
    entrieslink: "",
    resultslink: "https://eventingireland.com/",
    status: 'closed',
  },
  {
    id: 3,
    name: "Frankfort (Next Week)",
    eventdate: nowPlus(12),
    openingdate: nowMinus(10),
    closingdate: nowPlus(4),
    classes: _classes.national,
    isopen: true,
    islate: false,
    islive: false,
    hasentrylimits: true,
    entriesreceived: 121,
    maxentries: 250,
    entrieslink: "https://eventingireland.com/",
    resultslink: "",
    status: 'open',
  },
  {
    id: 7,
    name: "High Performance Training (Junior)",
    eventdate: nowPlus(15),
    openingdate: nowMinus(7),
    closingdate: nowPlus(3),
    classes: _classes.hp,
    isopen: true,
    islate: false,
    islive: false,
    hasentrylimits: false,
    entriesreceived: 245,
    maxentries: 250,
    entrieslink: "https://eventingireland.com/",
    resultslink: "",
    status: 'soonclose',
  },
  {
    id: 4,
    name: "Tyrella 2 (Opening Soon)",
    eventdate: nowPlus(17),
    openingdate: nowPlus(1),
    closingdate: nowPlus(13),
    classes: _classes.national,
    // openingdate: new Date(new Date().setDate(new Date().getDate() + 1)),
    // closingdate: new Date(new Date().setDate(new Date().getDate() + 10)),
    isopen: false,
    islate: false,
    islive: false,
    hasentrylimits: true,
    entriesreceived: 0,
    maxentries: 250,
    entrieslink: "",
    resultslink: "",
    status: 'soonopen',
  },
  {
    id: 5,
    name: "Blackstairs 1",
    eventdate: nowPlus(23),
    openingdate: nowPlus(10),
    closingdate: nowPlus(19),
    classes: _classes.national,
    isopen: false,
    islate: false,
    islive: false,
    hasentrylimits: true,
    entriesreceived: 0,
    maxentries: 250,
    entrieslink: "",
    resultslink: "",
    status: 'notopenyet',
  },
  {
    id: 6,
    name: "Tyrella 1 (Closing Soon)",
    eventdate: nowPlus(5),
    openingdate: nowMinus(7),
    closingdate: nowPlus(3),
    classes: _classes.national,
    isopen: true,
    islate: true,
    islive: false,
    hasentrylimits: true,
    entriesreceived: 245,
    maxentries: 250,
    entrieslink: "https://eventingireland.com/",
    resultslink: "",
    status: 'soonclose',
  }
];

function nowPlus(numDays) {
  const now = new Date();
  now.setDate(now.getDate() + numDays);
  return now;
}

function nowMinus(numDays) {
  const now = new Date();
  now.setDate(now.getDate() - numDays);
  return now;
}


