import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Typography, Link } from '@mui/material';
// auth
// import { useAuthContext } from '../../auth/useAuthContext';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import DisplaySteps from './DisplaySteps';

// ----------------------------------------------------------------------
const steps = [
  {
    title: "Click Forgot Password",
    description: "Click on the forgot passord link to set your password for the new system. "
  },
  {
    title: "Enter Your EMail Address and Membership Number",
    description: "When prompted enter your email address and membership number. Note: the email address must be the one already linked to your existing Eventing Ireland account. If you cannot remember what this is then send an email to headoffice@eventingireland.com to ask."
  },
  {
    title: "Click Send Request",
    description: "When you have entered your membership number and password then click the Send Request button and you will be emailed a 6 digit security code that you can use to reset your password."
  },
  {
    title: "Enter 6 Digit Code and your New Password",
    description: "When you have received your security code, enter it on the screen where indicated and set your new password."
  },
  {
    title: "That's It",
    description: "Don't forget to make a note of your new password which you can use from now on with the new system."
  }

];

export default function Login() {
  // const { method } = useAuthContext();

  return (
    <LoginLayout
      title="Welcome to our new Members Area"
      description={<DisplaySteps description="If you have an account and this is your first time to login then please follow the steps below:" steps={steps} />}
    >
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in to Eventing Ireland&apos;s Members Area</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            Create an account
          </Link>
        </Stack>

      </Stack>

      <AuthLoginForm />
      <Typography variant="h3" sx={{ display: { xs: "table-cell", md: "none" }, mt:5, mb: 3, maxWidth: 480, textAlign: 'center' }}>Please Note</Typography>
      <DisplaySteps sx={{ display: { xs: "table-cell", md: "none" } }} description="If you have an account and this is your first time to login then please follow the steps below:" steps={steps} />
    </LoginLayout>
  );
}
