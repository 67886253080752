
import PropTypes from 'prop-types';

import {    
    List,
    ListItem,
    Typography,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Stack,
} from '@mui/material';

DisplaySteps.propTypes = {
    steps: PropTypes.array,
    description: PropTypes.string,
    sx: PropTypes.object,
};

export default function DisplaySteps({ steps, description, sx }) {

    return (
        <Stack sx={sx}>
        {description && <Typography variant="caption">{description}</Typography>}
        <List
            disablePadding
        >
            {steps.map((w, index) => (
                <ListItem sx={{ mb: 3 }} disablePadding key={index} alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar>
                            {index + 1}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primaryTypographyProps={{
                            color: 'primary',
                            fontWeight: 'medium',
                            variant: 'body2',
                        }}
                        primary={w.title}
                        secondary={w.description}
                        secondaryTypographyProps={{
                            fontWeight: 'small',
                            variant: 'caption',
                        }}

                    />

                </ListItem>
            ))}
        </List>
        </Stack>
    )
}