import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import euroCartReducer from './slices/eurocart';
import poundCartReducer from './slices/poundcart';
import entriesReducer from './slices/entries';
import horsesReducer from './slices/horses';
import membershipsReducer from './slices/memberships';
import eventsReducer from './slices/events';
import settingsReducer from './slices/settings';
import paymentsReducer from './slices/payments';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const euroCartPersistConfig = {
  key: 'eurocart',
  storage,
  keyPrefix: 'redux-',
};

export const poundCartPersistConfig = {
  key: 'poundcart',
  storage,
  keyPrefix: 'redux-',
};

export const entriesPersistConfig = {
  key: 'entries',
  storage,
  keyPrefix: 'redux-',
};

export const eventsPersistConfig = {
  key: 'events',
  storage,
  keyPrefix: 'redux-',
};

export const membershipsPersistConfig = {
  key: 'memberships',
  storage,
  keyPrefix: 'redux-',
};

export const horsesPersistConfig = {
  key: 'horses',
  storage,
  keyPrefix: 'redux-',
};

export const paymentsPersistConfig = {
  key: 'payments',
  storage,
  keyPrefix: 'redux-',
};

export const settingsPersistConfig = {
  key: 'settings',
  storage,
  keyPrefix: 'redux-',
};


const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  eurocart: persistReducer(euroCartPersistConfig, euroCartReducer),
  poundcart: persistReducer(poundCartPersistConfig, poundCartReducer),
  product: persistReducer(productPersistConfig, productReducer),
  entries: persistReducer(entriesPersistConfig, entriesReducer),
  events: persistReducer(eventsPersistConfig, eventsReducer),
  horses: persistReducer(horsesPersistConfig, horsesReducer),
  memberships: persistReducer(membershipsPersistConfig, membershipsReducer),
  payments: persistReducer(paymentsPersistConfig, paymentsReducer),
  settings: persistReducer(settingsPersistConfig, settingsReducer),
});

export default rootReducer;
