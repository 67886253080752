import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { fDate, inTheFuture } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();

  const expires = () => {
    if(inTheFuture(user.memberexpirydate)) {
      return `Expiry: ${fDate(user.memberexpirydate)}`;
    }
    return `Expired: ${fDate(user.memberexpirydate)}`;
  }

  return (
    <Link component={RouterLink} to={PATH_DASHBOARD.general.settings} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }} style={{fontSize: 12}}>
            {user?.membertype}
            {" / "} 
            {user?.rideroption} 
          </Typography>
          {user.memberexpirydate && user.memberexpirydate !== "" && <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }} style={{fontSize: 12}}>
            {expires()}
          </Typography>}
        </Box>
      </StyledRoot>
    </Link>
  );
}
