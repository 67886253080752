import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'd MMM yyyy';


  return date && !noDate(date) ? format(date instanceof Date ? date : new Date(date), fm) : '';
}

export function fDateCompact(date, newFormat) {
  const fm = newFormat || 'd/MMM/yyyy';


  return date && !noDate(date) ? format(date instanceof Date ? date : new Date(date), fm) : '';
}

export function fDateTimeFancy(date, newFormat) {
  // const fm = newFormat || 'd MMM yyyy';
  if(!(date instanceof Date)) {
    date = new Date(date);
  }

  // // convert to local time
  // const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  const fm = newFormat || 'do MMMM, yyyy HH:mm';
  return date && !noDate(date) ? format(date instanceof Date ? date : new Date(date), fm) : '';
}

export function fDateFancy(date, newFormat) {
  // const fm = newFormat || 'd MMM yyyy';
  if(!(date instanceof Date)) {
    date = new Date(date);
  }
  const fm = newFormat || 'do MMMM, yyyy';
  return date && !noDate(date) ? format(date instanceof Date ? date : new Date(date), fm) : '';
}

export function noDate(date) {
  return !date || date === '0000-00-00';
}

export function fDateTime(date, newFormat) {
  if(!date) {
    return "";    
  }
  if(typeof date !== "string") {
    console.log("From Date: ", date);
    date = date.toISOString();
    console.log("To Iso Date: ", date);
  }
  // console.log(date);
  // console.log(typeof date);
  // const fm = newFormat || 'd MMM yyyy p';

  const datepart = fDate(date);
  const timepart = date.substring(11, 16);
  // console.log(datepart);
  // console.log(timepart);
  // return date ? format(date instanceof Date ? date : new Date(date), fm) : '';
  return `${datepart} ${timepart}`;
}

export function fTimestamp(date) {
  return date ? getTime(date instanceof Date ? date : new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(date instanceof Date ? date : new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function isThisYear(date) {
  if(!(date instanceof Date)) {
    date = new Date(date);
  }
  const now = new Date();
  return now.getFullYear() === date.getFullYear();
}

export function isSameDay(first, second) {
  if(!(first instanceof Date)) {
    first = new Date(first);
  }

  if(!(second instanceof Date)) {
    second = new Date(second);
  }

  return  first.getFullYear() === second.getFullYear() &&
          first.getMonth() === second.getMonth() &&
          first.getDate() === second.getDate();;
}

export function isBefore(first, second) {
  if(!(first instanceof Date)) {
    first = new Date(first);
  }

  if(!(second instanceof Date)) {
    second = new Date(second);
  }
  return first.getTime() < second.getTime();
}

export function withinNDays(then, n) {
  if(!(then instanceof Date)) {
    then = new Date(then);
  }
  
  const now = new Date();
  const msBetweenDates = Math.abs(then.getTime() - now.getTime());
  const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
  console.log(parseInt(daysBetweenDates, 10));
  return daysBetweenDates < n;
}


export function daysToGo(then) {
  if(!(then instanceof Date)) {
    then = new Date(then);
  }
  
  const now = new Date();
  const msBetweenDates = Math.abs(then.getTime() - now.getTime());
  const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
  return parseInt(daysBetweenDates, 10);
}



export function inTheFuture(date) {
  if(!(date instanceof Date)) {
    date = new Date(date);
  }
  const now = new Date();
  return date > now;
}

export function inThePast(date) {
  if(!(date instanceof Date)) {
    date = new Date(date);
  }const now = new Date();
  return date < now;
}

export function isToday(date) {
  if(!(date instanceof Date)) {
    date = new Date(date);
  }
  const today = new Date();
   
  // Compare the components of the dateToCheck with today's date
  const isSameDate =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
   
  // Return true if the dateToCheck is today, otherwise return false
  return isSameDate;
}

/**
 * Check if a date is within a certain number of hours from now.
 * 
 * @param {*} date 
 * @param {*} hours 
 */
export function isSoon(date, hours) {
  const now = new Date();
  if(!(date instanceof Date)) {
    date = new Date(date);
  }

  if(date < now) {
    // date is in the past so cannot be soon
    return false;
  }

  // if date is in future then 
  const soon = subtractHours(date, hours);

  return now > soon;
}

export function nth (d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

/**
 * Subtract a number of hours from a specific date
 * 
 * @param {*} date 
 * @param {*} hours 
 */
export function subtractHours(date, hours) {
  if(!(date instanceof Date)) {
    date = new Date(date);
  }

  date.setHours(date.getHours() - hours);

  return date;
}

export function subtractYears(date, years) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}