import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../routes/paths';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { login } = useAuthContext();
  const [error, setError] = useState();
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(false);
  const [username, setUsername] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const LoginSchema = Yup.object().shape({
    username: Yup.string(),
    password: Yup.string(),
  });


  const methods = useForm({
    resolver: yupResolver(LoginSchema)
  });

  const {
    reset,
    handleSubmit,
  } = methods;

  useEffect(() => {
      // Update network status
      const handleStatusChange = () => {
        setIsOnline(navigator.onLine);
      };

      // Listen to the online status
      window.addEventListener('online', handleStatusChange);

      // Listen to the offline status
      window.addEventListener('offline', handleStatusChange);

      // Specify how to clean up after this effect for performance improvment
      return () => {
        window.removeEventListener('online', handleStatusChange);
        window.removeEventListener('offline', handleStatusChange);
      };
  }, [isOnline]);

  const onSubmit = async (data) => {
    try {
      setError();
      if(!username || username === "") {
        setError("Please enter your membership number");
      } else if(!password || password === "") {
        setError("Please enter a password");
      } else {
        setLoggingIn(true);
        await login(username, password);
      }
      
    } catch (err) {
      console.error(err);
      reset();
      setError(err.message);
      setLoggingIn(false);

    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!isOnline && <Alert severity="warning">Your internet connection is offline, please connect to the internet to login</Alert>}
        {error && <Alert severity="error">{error}</Alert>}

        <RHFTextField name="username" label="Membership No." onChange={(event) => setUsername(event.target.value)}/>

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(event) => setPassword(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          to={PATH_AUTH.resetPassword}
          variant="body2"
          color="inherit"
          underline="always"
        >
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isLoggingIn}
        disabled={!isOnline}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
