import numeral from 'numeral';
import { isEuro } from './eventHelper';

// ----------------------------------------------------------------------

export function isNumber(value) {
  // return typeof value !== 'undefined' && Number.isNaN(value);
  return /^\d+$/.test(value);

}

export function fNumber(number) {
  return numeral(number).format();
}

export function currencyToSymbol(currency) {
  return currency === 'euro' ? '€' : '£';
}

export function fCurrency(number, currency = '€') {
  const numberFormat = `${currency}0,0.00`;
  if(number) {
    const format = number ? numeral(number).format(numberFormat) : '';
    return result(format, '.00');
  }
  return number;
}


export function formatCurrency(number, currency) {
  let cur = 'EUR';
  if(!isEuro(currency)) {
    cur = 'GBP';
  }
  const value = Number(number)
  return value.toLocaleString('en-GB', { style: 'currency', currency: cur});
}


export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function ordinal_suffix_of(i) {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
      return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
}