import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  settings: {
    shop: [],
    daytickets: []
  }
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSettingsSuccess(state, action) {
      state.isLoading = false;
      state.settings = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getSettingsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function loadSettings() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/account/my/settings');
      dispatch(slice.actions.getSettingsSuccess(response.data.record ? response.data.record : []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export const getSettings = state => state.settings;
