import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'
import Label from '../label';

// material
// ----------------------------------------------------------------------

PoundCartSize.propTypes = {
};


export default function PoundCartSize() {
  const { poundcart, isLoading } = useSelector((state) => state.poundcart);

  return (
    <>{!isLoading && poundcart.items.length > 0 && <Label color="error"> {poundcart.items.length }</Label>}</>
  )
}

