// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------
const env       = process.env.REACT_APP_NODE_ENV || 'development';
let apiroot= '/api/';
let eiweb = '';
let worldnetEurTermId = "2414010";
let is_test_system = false;

if (env === 'development') {
  apiroot= 'http://localhost:4444/';
  eiweb="https://unbridledresults.com";
  worldnetEurTermId = "2697001";
  is_test_system = true;
} 

if (env === 'test') {
  apiroot= 'https://api.unbridledresults.com/';
  eiweb="https://unbridledresults.com";
  worldnetEurTermId = "2697001";
  is_test_system = true;
} 
if (env === 'production') {
  apiroot= 'https://api.eventingireland.com/';
  eiweb="https://www.eventingireland.com";
  worldnetEurTermId = "2414010";
} 
if (env === 'newproduction') {
  apiroot= 'https://api.unbresults.com/';
  eiweb="https://unbresults.com";
  worldnetEurTermId = "2697001";
  is_test_system = true;
} 

// export const API_ROOT = apiroot;
export const IS_TEST_SYSTEM = is_test_system;
export const WORLDNET_EURO_TERMINAL_ID = worldnetEurTermId;
export const HOST_API_KEY = apiroot;
export const EVENTING_IRELAND_WEBSITE = eiweb;
export const EVENTING_IRELAND_RESULTS = env === 'production' ? "https://results.eventingireland.com" : "https://results.unbridledresults.com";
export const EVENTING_IRELAND_MER_RULES = 'https://www.eventingireland.com/app/uploads/2023/02/MERs-2023-1.pdf';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};


export const LINEITEMTYPE = {
  ADMIN_CHARGE_EURO: 'P',
  EVENT_ENTRY: 'E',
  EVENT_APPLICATION: 'A',
  STABLE_BOOKING: 'P',
  ELECTRIC_HOOKUP: 'P',
  SHOP_ITEM: 'S',
  DAY_MEMBERSHIP: 'P',
  DAY_REGISTRATION: 'P',
  HORSE_REGISTRATION: 'R',
  MEMBER_REGISTRATION: 'M',
  HORSE_REGISTRATION_UPGRADE: 'RU',
  MEMBER_UPGRADE: 'MU',
  LATE_ENTRY_FEE: 'P',
  EXTRA_FEE: 'P',
  DISCOUNT: 'REF',
};

export const REFITEMID = {
  ADMIN_CHARGE_EURO: null,
  EVENT_ENTRY: null,
  STABLE_BOOKING: 1,
  ELECTRIC_HOOKUP: 3,
  SHOP_ITEM: 14,
  DAY_MEMBERSHIP: 8,
  DAY_REGISTRATION: 9,
  LATE_ENTRY_FEE: 15,
  MEMBER_REGISTRATION: 5,
  MEMBER_UPGRADE: 7,
  DISCOUNT: 0,
  HORSE_REGISTRATION: 9,

};


// MISC
export const LIMITS = {
  PREVIOUS_RIDER_ENTRIES: 5,
  PREVIOUS_HORSE_ENTRIES: 5,
}

export const INTERNATIONAL_ENTRY = {
  NORMAL: 0,
  SELECT_APPLICATION: 1,
  ADD_HORSE: 2,
  LATE_APPLICATION_ENTRY_FEE: 15
}

// SETTINGS
export const SETTINGS = {
  ADMIN_FEE: 3,
  STG_ADMIN_FEE: 2,
  EURO3DIGIT: 'euro',
  EURO: 'euro',
  POUND: 'pound',
  WEB_ONLY_MEMBERTYPE_ID: 1,
  WEB_ONLY_MEMBERTYPE_LABEL: 'Web Only',
  WEB_ONLY_REGISTRATION_ID: 5,
  CART_POPOVER_NUM_ITEMS: 4,    
  MAX_HORSE_AGE: 25,
  PONY_REGISTRATION_TYPE: 4,
  LIFE_MEMBERSHIP_ID: 4,
  NON_RIDING_OPTION_ID: 15,
  PONY_RIDING_OPTION_ID: 8,
  JUNIOR_RIDING_OPTION_ID: 7,
  YOUNG_RIDING_OPTION_ID: 16,
  RESTRICTED_RIDING_OPTION_ID: 11,
  SENIOR_RIDING_OPTION_ID: 9,
  OVERSEAS_RIDING_OPTION_ID: 13,
  SEASON_ENDS: '31/10',
  MINIMUM_AGE: 12,
  RESTRICTED_HORSE_REGISTRATION_TYPE_ID: 3,
  HORSE_UPGRADE_FEE: 35.00,
  RESTRICTED_RIDER_UPGRADE_FEE: 70.00,
  MEMBER_UPGRADE_FEE: 35.00,
  TRAINING_EVENT_TYPE: 6,
  HP_EVENT_TYPE: 8,
  NON_RIDING_OPTION_NAME: 'Non-Riding',
  IRISH_FEDERATION: 'IE',
  STABLING: 'ST',
  HOOKUP: 'HU',
}

export const TEXT_ELEMENTS = {
  HORSE_CHANGE_OF_OWNERSHIP: 'If you are the new owner of this horse but the horse is still registered to the old owner then please submit a Change of Ownership Form to headoffice@eventingireland.com'
};

export const DAY_TICKET_IDS = {
  MEMBERSHIP_EURO: 1,
  MEMBERSHIP_GBP: 2,
  REGISTRATION_EURO: 3,
  REGISTRATION_GBP: 4,
}

export const DISCOUNTS = [
  {
    name: 'Late Entry Fee',    
  },
  {
    name: 'Change Of Ownership'
  },
]

export const ERQI_UNKNOWN = {
  title: 'Unknown',
  restricted: false,
  bgcolour: 'rgba(173, 216, 230, 1)',
  description: "Horse Safety Rating Unknown"
}

export const ERQI_PENDING = {
  title: 'Pending',
  restricted: false,
  bgcolour: 'rgba(173, 216, 230, 1)',
  description: 'A minimum of three runs XC are required to assess this horses form.'
}

export const ERQI_TABLE = [
  {
      title: 'E',
      lower: -5,
      upper: 0,
      restricted: true,
      bgcolour: 'rgba(239,124,124,1)',
      description: 'Sorry the ERQI Rating for this horse is not sufficient for this class'
  },
  {
      title: 'D',
      lower: 0,
      upper: 1,
      restricted: false,
      bgcolour: 'rgba(255,188,71,0.8)',
      description: 'The ERQI Rating for this level indicates low form for this level'
  },
  {
      title: 'C',
      lower: 1,
      upper: 2,
      restricted: false,
      bgcolour: 'rgba(97,202,49,0.15)',
      description: 'The ERQI Rating for this level indicates below average form'
  },
  {
      title: 'B',
      lower: 2,
      upper: 3,
      restricted: false,
      bgcolour: 'rgba(97,202,49,0.3)',
      description: 'The ERQI Rating for this level indicates above average form'
  },
  {
      title: 'A',
      lower: 3,
      upper: 4,
      restricted: false,
      bgcolour: 'rgba(97,202,49,0.5)',
      description: 'The ERQI Rating for this level indicates good form'
  },
  {
      title: 'A+',
      lower: 4,
      upper: 5,
      restricted: false,
      bgcolour: 'rgba(97,202,49,0.65)',
      description: 'The ERQI Rating for this level indicates very good form'
  },
  {
      title: 'A++',
      lower: 5,
      upper: 8,
      restricted: false,
      bgcolour: 'rgba(97,202,49,0.8)',
      description: 'The ERQI Rating for this level indicates excellent form'
  }
];
