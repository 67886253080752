import sum from 'lodash/sum';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  poundcart: {
    items: [],
    total: 0,
  },
  adminfee: 3
};

const slice = createSlice({
  name: 'poundcart',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // fetched cart
    getCartSuccess(state, action) {
      state.isLoading = false;
      state.poundcart.items = action.payload;
      state.total = sum(action.payload.map((item) => item.amount));
    },

    // // CHECKOUT
    // getCart(state, action) {
    //   const items = action.payload;
    //   const total = sum(items.map((item) => item.amount));
    //   state.poundcart.items = items;
    //   state.poundcart.total = sum(items.map((item) => item.amount));
    // },

    addToCart(state, action) {
        const newitems = action.payload;
        state.isLoading = false;
        state.poundcart.items = [...state.poundcart.items, ...newitems];
        state.poundcart.total = sum(state.poundcart.items.map((item) => item.amount));
    },

    resetPoundCart(state) {
      state.poundcart.items = [];
      state.poundcart.total = 0;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addToCart,
  resetPoundCart,
} = slice.actions;

// ----------------------------------------------------------------------

export function loadPoundCartItems() {
  return async (dispatch) => {
    dispatch(slice.actions.resetPoundCart());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/account/cart/pound');
      dispatch(slice.actions.getCartSuccess(response.data.record && response.data.record !== {} ? response.data.record.Cartitems : []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addPoundCartItems(newitems) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/account/cart/pound', newitems);
      dispatch(slice.actions.addToCart(response.data.records));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeCartItem(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/account/cart/pound/${itemId}`);
      dispatch(slice.actions.getCartSuccess(response.data.record && response.data.record !== {} ? response.data.record.Cartitems : []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getPoundCartStableCount = (cart, eventname) => cart.items.filter(ci => ci.description.includes(`Onsite stabling at ${eventname}`)).length;
export const getPoundCartHookupCount = (cart, eventname) => cart.items.filter(ci => ci.description.includes(`Onsite hookup at ${eventname}`)).length;
