import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  AccountCreatedPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  DashboardPage,
  // KitchenSinkPage,
  Page500,
  Page403,
  Page404,
  ComingSoonPage,
  MaintenancePage,
  //

  // EI
  MyEntriesPage,
  MyHorsesPage,
  EventPage,
  ClassPage,
  DressageTestPage,
  DressageTestsPage,
  EventsPage,
  SettingsPage,
  VolunteeringPage,
  ShopPage,
  ShopDetailsPage,
  PaymentsPage,
  PaymentProcessedPage,
  PaymentProcessedSuccessfulPage,
  PaymentProcessedFailedPage,
  MembershipsPage,
  PaymentDetailsPage,
  MembershipDetailsPage,
  EntryDetailsPage,
  HorseDetailsPage,
  RegisterHorsePage,
  RegisterMemberPage,
  EuroCartPage,
  PoundCartPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
              <RegisterPage />
          ),
        },
        {
          path: 'account-created',
          element: (
              <AccountCreatedPage />
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        { path: 'account-created', element: <AccountCreatedPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      // path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <DashboardPage /> },
        { path: 'myentries', element: <MyEntriesPage /> },
        { path: 'entry/:id', element: <EntryDetailsPage /> },
        { path: 'events', element: <EventsPage /> },
        { path: 'events/:id', element: <EventPage /> },
        { path: 'cart/euro', element: <EuroCartPage /> },
        { path: 'cart/pound', element: <PoundCartPage /> },
        { path: 'events/class/:eventid/:classid', element: <ClassPage /> },
        { path: 'payment/processed', element: <PaymentProcessedPage /> },
        { path: 'payments/success', element: <PaymentProcessedSuccessfulPage /> },
        { path: 'payments/failed', element: <PaymentProcessedFailedPage /> },
        { path: 'payments', element: <PaymentsPage /> },
        { path: 'payments/:id', element: <PaymentDetailsPage /> },
        { path: 'tests', element: <DressageTestsPage /> },
        { path: 'tests/:id', element: <DressageTestPage /> },
        { path: 'shop', element: <ShopPage /> },
        { path: 'shop/:id', element: <ShopDetailsPage /> },
        { path: 'horses', element: <MyHorsesPage /> },
        { path: 'horses/:id', element: <HorseDetailsPage /> },
        { path: 'horse/add', element: <RegisterHorsePage /> },
        { path: 'member/add', element: <RegisterMemberPage /> },
        { path: 'memberships', element: <MembershipsPage /> },
        { path: 'memberships/:id', element: <MembershipDetailsPage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'volunteering', element: <VolunteeringPage /> },
      ],
    },    
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
