import { createSlice } from '@reduxjs/toolkit';

// utils
import { inTheFuture } from '../../utils/formatTime';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  entries: []
};

const slice = createSlice({
  name: 'entries',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getEntriesSuccess(state, action) {
      state.isLoading = false;
      state.entries = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getEntriesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function loadEntries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/account/my/entries');
      // console.debug(response.data);
      dispatch(slice.actions.getEntriesSuccess(response.data.records ? response.data.records : []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export const getEntries = state => state.entries;
export const getUpcomingEntries = state => state.entries.filter((entry) => inTheFuture(entry.eventdate));