import { useSelector } from 'react-redux'
import Label from '../label';

// material
// ----------------------------------------------------------------------


export default function EuroCartSize() {
  const { eurocart, isLoading } = useSelector((state) => state.eurocart);

  return (
    <>{!isLoading && eurocart.items.length > 0 && <Label color="error"> {eurocart.items.length }</Label>}</>
  )
}

