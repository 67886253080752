import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  memberships: []
};

const slice = createSlice({
  name: 'memberships',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // fetched cart
    getMembershipsSuccess(state, action) {
      state.isLoading = false;
      state.memberships = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getmembershipsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function loadMemberships() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/account/my/memberships');
      // console.debug(response.data);
      dispatch(slice.actions.getMembershipsSuccess(response.data.records ? response.data.records : []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export const getMemberships = state => state.memberships;
