import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const AccountCreatedPage = Loadable(lazy(() => import('../pages/auth/AccountCreatedPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

// DASHBOARD: GENERAL
export const DashboardPage = Loadable(lazy(() => import('../ei/pages/DashboardPage')));

export const MyEntriesPage = Loadable(
  lazy(() => import('../ei/pages/MyEntriesPage'))
);

export const MyHorsesPage = Loadable(
  lazy(() => import('../ei/pages/MyHorsesPage'))
);

export const EventPage = Loadable(
  lazy(() => import('../ei/pages/EventPage'))
);

export const EventsPage = Loadable(
  lazy(() => import('../ei/pages/EventsPage'))
);

export const SettingsPage = Loadable(lazy(() => import('../ei/pages/SettingsPage')));

export const VolunteeringPage = Loadable(lazy(() => import('../ei/pages/VolunteeringPage')));

export const ShopPage = Loadable(
  lazy(() => import('../ei/pages/ShopPage'))
);

export const DressageTestsPage = Loadable(
  lazy(() => import('../ei/pages/DressageTestsPage'))
);

export const DressageTestPage = Loadable(
  lazy(() => import('../ei/pages/DressageTestPage'))
);

export const ShopDetailsPage = Loadable(
  lazy(() => import('../ei/pages/ShopDetailsPage'))
);


export const PaymentsPage = Loadable(
  lazy(() => import('../ei/pages/PaymentsPage'))
);

export const PaymentDetailsPage = Loadable(
  lazy(() => import('../ei/pages/PaymentDetailsPage'))
);

export const PaymentProcessedPage = Loadable(
  lazy(() => import('../ei/pages/PaymentProcessedPage'))
);


export const PaymentProcessedSuccessfulPage = Loadable(
  lazy(() => import('../ei/pages/PaymentProcessedSuccessfulPage'))
);


export const PaymentProcessedFailedPage = Loadable(
  lazy(() => import('../ei/pages/PaymentProcessedFailedPage'))
);


export const MembershipsPage = Loadable(
  lazy(() => import('../ei/pages/MembershipsPage'))
);

export const MembershipDetailsPage = Loadable(
  lazy(() => import('../ei/pages/MembershipDetailsPage'))
);

export const EntryDetailsPage = Loadable(
  lazy(() => import('../ei/pages/EntryDetailsPage'))
);

export const HorseDetailsPage = Loadable(
  lazy(() => import('../ei/pages/HorseDetailsPage'))
);

export const RegisterHorsePage = Loadable(
  lazy(() => import('../ei/pages/RegisterHorsePage'))
);

export const RegisterMemberPage = Loadable(
  lazy(() => import('../ei/pages/RegisterMemberPage'))
);


export const EuroCartPage = Loadable(
  lazy(() => import('../ei/pages/EuroCartPage'))
);

export const PoundCartPage = Loadable(
  lazy(() => import('../ei/pages/PoundCartPage'))
);

export const ClassPage = Loadable(
  lazy(() => import('../ei/pages/ClassPage'))
);


// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

