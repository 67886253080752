import sum from 'lodash/sum';
import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
import { fixDetails } from '../../utils/eventHelper';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  eurocart: {
    items: [],
    total: 0,
  },
  adminfee: 3
};

const slice = createSlice({
  name: 'eurocart',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // fetched cart
    getCartSuccess(state, action) {
      console.debug("Saving cart entries");
      console.debug(action);
      state.isLoading = false;
      state.eurocart.items = action.payload;
      state.eurocart.items = state.eurocart.items.map(e => fixDetails(e));
      state.total = sum(action.payload.map((item) => item.amount));
    },

    // // CHECKOUT
    // getCart(state, action) {
    //   const items = action.payload;
    //   const total = sum(items.map((item) => item.amount));
    //   state.cart.items = items;
    //   state.cart.total = sum(items.map((item) => item.amount));
    // },

    addToCart(state, action) {
        const newitems = action.payload;
        state.isLoading = false;
        state.eurocart.items = [...state.eurocart.items, ...newitems];
        state.eurocart.total = sum(state.eurocart.items.map((item) => item.amount));
    },

    
    resetEuroCart(state) {
      state.eurocart.items = [];
      state.eurocart.total = 0;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addToCart,
  resetEuroCart,
} = slice.actions;

// ----------------------------------------------------------------------

export function loadEuroCartItems() {
  return async (dispatch) => {
    dispatch(slice.actions.resetEuroCart());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/account/cart/euro');
      console.debug(response.data);
      dispatch(slice.actions.getCartSuccess(response.data.record && response.data.record !== {} ? response.data.record.Cartitems : []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addEuroCartItems(newitems) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/account/cart/euro', newitems);
      dispatch(slice.actions.addToCart(response.data.records));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function euroCheckout(options) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/account/cart/euro/checkout', options);
//       if(response.data.error) {
//         dispatch(slice.actions.hasError(response.data.error));  
//       } else {
//         dispatch(slice.actions.addToCart(response.data.records));
//       }
      
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function removeCartItem(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response =  await axios.delete(`/account/cart/euro/${itemId}`);
      dispatch(slice.actions.getCartSuccess(response.data.record && response.data.record !== {} ? response.data.record.Cartitems : []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getEuroCartStableCount = (cart, eventname) => cart.items.filter(ci => ci.description.includes(`Onsite stabling at ${eventname}`)).length;
export const getEuroCartHookupCount = (cart, eventname) => cart.items.filter(ci => ci.description.includes(`Onsite hookup at ${eventname}`)).length;

export const isMembershipRenewalInCart = (cart, userid) => cart.items.find(ci => ci.category.startsWith('MR:MT2:') && ci.details.member && ci.details.member.id === userid);
