import PropTypes from 'prop-types';
// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
  description: PropTypes.node,
};

export default function LoginLayout({ children, illustration, description, title }) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

    <StyledContent>
      <Stack sx={{ width: 1 }}> {children} </Stack>
    </StyledContent>

      <StyledSection>
        <Typography variant="h3" sx={{ mb: 3, maxWidth: 480, textAlign: 'center' }}>
          {title || 'Hi, Welcome back'}          
        </Typography>
        {description && <Typography sx={{ mb: 3, maxWidth: 480, textAlign: 'center' }}>
          {description}          
        </Typography>}


        <StyledSectionBg />
      </StyledSection>

    </StyledRoot>
  );
}
